import { combineReducers } from 'redux';
import auth from './authReducers';
import profileData from './userProfileReducers';
import register from './registerReducers';
import challenges from './challengeReducers';
import social from './socialReducers';
import wellnessDashboard from './wellnessDashboardReducers';
import events from './eventReducers';
import wellBeingAssessment from './wellbeingAssessmentReducers';
import peopleHome from './peopleHomeReducers';
import education from './educationReducers';
import companyDashboard from './companyDashboardReducers';
import userNotification from './userNotificationReducers';
import logWorkout from './LogWorkoutReducers';
import surveys from './surveyReducers';
import stripeData from './stripeReducers';
import challengesAdmin from './adminChallengesReducers';
import adminTeamCreation from './adminTeamCreation';
import adminReports from './wellbeingAdminReport';
export default combineReducers({
  auth,
  profileData,
  register,
  challenges,
  social,
  wellnessDashboard,
  events,
  wellBeingAssessment,
  peopleHome,
  education,
  companyDashboard,
  userNotification,
  logWorkout,
  surveys,
  stripeData,
  challengesAdmin,
  adminTeamCreation,
  adminReports
});