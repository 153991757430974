/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */
import map from 'lodash/map';
import isNull from 'lodash/isNull';

import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  isLoading: false,
  networkError: null,
  adventureCategories: [],
  themes: [],
  selectedAdventureCategories: [],
  selectedvideoLibraries: [],
  selectedWebinarVideos: [],
  selectedRecipeCategories: [],
  adventureCategoriesError: null,
  featuredJourney: null,
  adventures: null,
  adventuresError: null,
  adventureArticle: null,
  adventureArticleError: null,
  count: null,
  adventureIds: null,
  videoLibraries: [],
  videos: null,
  videoLibraryArticle: null,
  videoCount: null,
  videoIds: null,
  featuredVideos: null,
  featuredVideosError: null,
  pointsSuccess: null,
  pointsSuccessError: null,
  quizQuestions: null,
  quizScore: null,
  adventureScoreBoard: null,
  webinarLibraries: [],
  webinarVideos: null,
  webinarVideoCount: null,
  webinarVideoIds: null,
  webinarLibraryArticle: null,
  recipeDetailsById: {},
  featuredRecipes: [],
  recipeCategories: [],
  recipeCategoryDetails: [],
  recommendedRecipes: [],
  recipeLoading: false,
  favoriteArticles: [],
  favoriteVideos: [],
  favoriteRecipes: [],
  userQuizScore: [],
  featuredWebinarVideos: null,
  featuredWebinarVideosError: null,

  featureVideos: null,
  featureVideosError: null,
  recommendedArticle: null,
  journeyDetails:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  
  case actionTypes.ADVENTURE_SCORE_BOARD:
    return {
      ...state,
      adventureScoreBoard: action.res
    }

  case actionTypes.FETCH_SCORE:
    return {
      ...state,
      quizQuestions: action.quizQuestions,
      quizScore: action.quizScore
    }

  case actionTypes.FETCH_QUIZZES:
    return {
      ...state,
      quizScore: null,
      quizQuestions: action.quizQuestions,
      isLoading: false
    }

  case actionTypes.CLEAR_THE_QUIZ:
    return {
      ...state,
      quizQuestions: null
    }

  case actionTypes.EDUCATION_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.GET_VIDEOS:
    return {
      ...state,
      videos: isNull(action.data) ? [] : action.data,
      videoCount: isNull(action.data) ? 0 : action.data.length,
      videoIds: isNull(action.data) ? [] : map(action.data, 'id')
    };

  case actionTypes.GET_WEBINAR_VIDEOS:
    return {
      ...state,
      webinarVideos: isNull(action.data) ? [] : action.data,
      webinarVideoCount: isNull(action.data) ? 0 : action.data.length,
      webinarVideoIds: isNull(action.data) ? [] : map(action.data, 'id')
    };

  case actionTypes.GET_WEBINAR_LIBRARY_ARTICLE:
    const webinarLibraryArticle = state.webinarVideos && state.webinarVideos.filter((video) => action.id == video.id);
    return webinarLibraryArticle.length > 0 ? {
      ...state,
      webinarLibraryArticle: webinarLibraryArticle[0]
    } : {
      ...state
    };
    
  case actionTypes.GET_VIDEOS_LIBRARY:
    return {
      ...state,
      videoLibraries: action.data
    };

  case actionTypes.GET_SELECTED_VIDEOS_LIBRARY:
    return {
      ...state,
      selectedvideoLibraries: action.selectedvideoLibraries
    };

  case actionTypes.GET_WEBINAR_VIDEO_CATEGORY:
    return {
      ...state,
      webinarLibraries: action.data
    };

  case actionTypes.GET_SELECTED_WEBINAR_VIDEO_CATEGORY:
    return {
      ...state,
      selectedWebinarVideos: action.selectedWebinarVideos
    };

  case actionTypes.SET_FEATURED_JOURNEY:
    return {
      ...state,
      featuredJourney: isNull(action.journeys) ? [] : action.journeys
    };

  case actionTypes.LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError
    };

  case actionTypes.GET_ADVENTURE_CATEGORIES:
    return {
      ...state,
      adventureCategories: isNull(action.adventureCategories) ? [] : action.adventureCategories
    };

  case actionTypes.GET_THEMES:
    return {
      ...state,
      themes: isNull(action.themes) ? [] : action.themes
    };

  case actionTypes.GET_SELECTED_ADVENTURE_CATEGORIES:
    return {
      ...state,
      selectedAdventureCategories: isNull(action.selectedAdventureCategories) ? [] : action.selectedAdventureCategories
    };

  case actionTypes.FAILED_GET_ADVENTURE_CATEGORIES:
    return {
      ...state,
      adventureCategoriesError: action.adventureCategoriesError
    };

  case actionTypes.GET_CATEGORY_ADVENTURES:
    return {
      ...state,
      adventures: isNull(action.adventures) ? [] : action.adventures,
      count: isNull(action.adventures) ? 0 : action.adventures.length,
      adventureIds: isNull(action.adventures) ? [] : map(action.adventures, 'id')
    };

  case actionTypes.FAILED_GET_CATEGORY_ADVENTURES:
    return {
      ...state,
      adventuresError: action.adventuresError
    };

  case actionTypes.GET_ADVENTURE_ARTICLE:
    const adventureArticle = state.adventures && state.adventures.filter((adventure) => action.id == adventure.id);
    return {
      ...state,
      adventureArticle: adventureArticle && adventureArticle[0]
    };

  case actionTypes.FAILED_GET_ADVENTURE_ARTICLE:
    return {
      ...state,
      adventureArticleError: action.adventureArticleError,
      adventureArticle: {}
    };

  case actionTypes.GET_VIDEO_LIBRARY_ARTICLE:
    const videoLibraryArticle = state.videos && state.videos.filter((video) => action.id == video.id);
    return !isNull(videoLibraryArticle) && videoLibraryArticle.length > 0 ? {
      ...state,
      videoLibraryArticle: videoLibraryArticle[0]
    } : {
      ...state
    };
    
  case actionTypes.GET_RECOMMENDED_VIDEOS:
    return {
      ...state,
      featuredVideos: action.featuredVideos
    };

  case actionTypes.GET_FEATURED_VIDEOS:
    return {
      ...state,
      featureVideos: action.featureVideos
    };  

  case actionTypes.FAILED_GET_RECOMMENDED_VIDEOS:
    return {
      ...state,
      featuredVideosError: action.featuredVideosError
    };

  case actionTypes.GET_NEW_WEBINAR_VIDEOS:
    return {
      ...state,
      featuredWebinarVideos: action.featuredWebinarVideos
    };

  case actionTypes.FAILED_GET_NEW_WEBINAR_VIDEOS:
    return {
      ...state,
      featuredWebinarVideosError : action.featuredWebinarVideosError
    };
  case actionTypes.FAILED_GET_FEATURED_VIDEOS:
    return {
      ...state,
      featureVideosError: action.featureVideosError
    };  

  case actionTypes.UPDATE_GLOBAL_POINTS:
    return {
      ...state,
      pointsSuccess: action.pointsSuccess
    };

  case actionTypes.FAILED_UPDATE_GLOBAL_POINTS:
    return {
      ...state,
      pointsSuccessError: action.pointsSuccessError
    };

  case actionTypes.UPDATE_LOCK_STATUS:
    const newAdventures = [...state.adventures];
    const adventure = newAdventures && newAdventures.filter((adventure) => adventure.id === action.id);
    let index = newAdventures && newAdventures.findIndex((adventure) => adventure.id === action.id);
    if(index >= 0) {
      adventure[0].lock_status = 1;
      newAdventures[index] = adventure[0];
    }

    const newFeaturedJourney = [...state.featuredJourney];
    const featureIndex = newFeaturedJourney && newFeaturedJourney.findIndex((adventure) => adventure.id === action.id);
    if(featureIndex >= 0 ) {
      newFeaturedJourney[featureIndex].lock_status = 1;
    }

    return {
      ...state,
      adventures: newAdventures,
      featuredJourney: newFeaturedJourney
    };

  case actionTypes.EDIT_WELLNESS_JOURNEY:
    return {
      ...state,
      journeyEditSuccess: action.journeyEditSuccess,
      isLoading: false
    };

  case actionTypes.ADD_WELLNESS_JOURNEY:
    return {
      ...state,
      addJourneySuccess: action.addJourneySuccess,
      isLoading: false
    };

  case actionTypes.QUIZ_SUBMIT_SUCCESS:
    return{
      ...state,
      isLoading: false
    };

  case actionTypes.QUIZ_SUBMIT_FAIL:
    return{
      ...state,
      isLoading: false
    };
  case actionTypes.GET_RECIPES_DETAILS_BY_ID:
    return{
      ...state,
      recipeDetailsById: action.recipeDetails
    };
  case actionTypes.GET_FEATURED_RECIPES_DETAILS:
    return{
      ...state,
      featuredRecipes: action.featuredRecipes
    };
  case actionTypes.GET_RECIPES_CATEGORIES:
    return {
      ...state,
      recipeCategories: action.recipeCategories
    };
  case actionTypes.GET_SELECTED_RECIPES_CATEGORIES:
    return {
      ...state,
      selectedRecipeCategories: action.selectedRecipeCategories
    };
  case actionTypes.GET_RECIPES_CATEGORY_DETAILS:
    return {
      ...state,
      recipeCategoryDetails: action.recipeCategoryDetails
    };
  case actionTypes.GET_RECOMMENDED_RECIPES_DETAILS:
    return {
      ...state,
      recommendedRecipes: action.recommendedRecipes
    };
  case actionTypes.RECIPE_LOADING_FALSE:
    return {
      ...state,
      recipeLoading: false
    };
  case actionTypes.RECIPE_LOADING_TRUE:
    return {
      ...state,
      recipeLoading: true
    };
  case actionTypes.ADD_FITNESS_VIDEO:
    return{
      ...state,
      addVideoSuccess : action.addVideoSuccess,
      isLoading: false
    }
  case actionTypes.GET_FITNESS_VIDEO_BY_ID:
    return{
      ...state,
      fitnessVideo : action.data
    }
  case actionTypes.EDIT_FITNESS_VIDEO:
    return{
      ...state,
      videoEditSuccess : action.videoEditSuccess,
      isLoading: false
    }
  case actionTypes.GET_FAVORITE_ARTICLES:
    return{
      ...state,
      favoriteArticles : action.data,
    }
  case actionTypes.GET_FAVORITE_RECIPES:
    return{
      ...state,
      favoriteRecipes : action.data,
    }
  case actionTypes.GET_FAVORITE_VIDEOS:
    return{
      ...state,
      favoriteVideos : action.data,
    }
  case actionTypes.GET_USER_QUIZ_SCORE:
    return {
      ...state,
      userQuizScore: action.data
    }; 
  case actionTypes.SET_RECOMMENDED_ARTICLE:
    return {
      ...state,
      recommendedArticle: isNull(action.journeys) ? [] : action.journeys
    };
  case actionTypes.GET_WELLNESS_JOURNEYS_ID:
    return {
      ...state,
      journeyDetails: action.journeyDetails
    };   
  default: break;
  }
  return state;
};

export default reducer;