/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */
import * as actionTypes from '../../actions/actionTypes';
import { descendingOrderSort } from '../../../utils/methods';

const initialState = {
  eventCreated: null,
  createEventError: null,
  isLoading: false,
  places: null,
  networkError: null,
  placesError: null,
  events: [],
  eventsError: null,
  activeEventsError: null,
  activeEvents: [],
  pastEventsError: null,
  pastEvents: [],
  eventDetailError: null,
  eventDetail: {},
  comments: null,
  commentError: null,
  commentSuccess: null,
  postCommentError: null,
  replySuccess: null,
  replyError: null,
  totalCount: null,
  attendSuccess: null,
  attendError: null,
  attendees: [],
  attendeesError: null,
  featureEvents: null,
  attending: 0,
  searchedAttendees: null,
  ondemandEventDetailErr: null,
  ondemandEventDetail: {},
  createOndemandEventError: null,
  eventCreatedOndemand: null,
  ondemandCategory: [],
  ondemandCategoryErr: null,
  ondemandByIdData:[],
  deleteOndemand:[],
  deleteEvent:[],
  wolibaEvents:[],
  companyEvents:[],
  inviteEventUsers:[],
  eventUserList:[]
};

const updateList = (list, id, isAttending) => {
  let index = list.findIndex((post) => post.id === id);
  let updationData = [...list];
  if(index > -1) {
    updationData[index].is_attending = isAttending === 1 ? 0 : 1;
  }
  return updationData;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.GET_FEATURED_EVENT:
    return {
      ...state,
      featureEvents: state.featureEvents
    };
  
  case actionTypes.SET_FEATURED_EVENT:
    return {
      ...state,
      featureEvents: action.data.event
    };
  case actionTypes.SUCCESSFULLY_CREATED_EVENT:
    return {
      ...state,
      eventCreated: action.successResponse,
      isLoading: false
    };

  case actionTypes.FAILED_CREATE_EVENT:
    return {
      ...state,
      createEventError: action.createEventError,
      isLoading: false
    };

  case actionTypes.FAILED_CREATE_ONDEMAND_EVENT:
    return {
      ...state,
      createOndemandEventError: action.error,
      isLoading: false
    };

  case actionTypes.SUCCESSFULLY_CREATED_ONDEMAND_EVENT:
    return {
      ...state,
      eventCreatedOndemand: action.successResponse,
      isLoading: false
    };    

  case actionTypes.EVENT_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.GET_PLACES:
    return {
      ...state,
      places: action.places,
      isLoading: false
    };

  case actionTypes.FAILED_GET_PLACES:
    return {
      ...state,
      isLoading: false,
      placesError: action.placesError
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError,
      isLoading: false
    };

  case actionTypes.GET_EVENTS:
    let events = [];
    events = action.PageNumber === 1 ? [...action.events] : [
      ...state.events,
      ...action.events
    ];
    return {
      ...state,
      events: events,
      totalCount: action.totalCount,
      isLoading: false
    };

  case actionTypes.FAILED_GET_EVENTS:
    return {
      ...state,
      eventsError: action.eventsError,
      isLoading: false
    };

  case actionTypes.FAILED_GET_ACTIVE_EVENTS:
    return {
      ...state,
      activeEventsError: action.activeEventsError
    };

  case actionTypes.GET_ACTIVE_EVENTS:
    let activeEvent = [];
    activeEvent = action.PageNumber === 1 ? [...action.activeEvents] : [
      ...state.activeEvents,
      ...action.activeEvents
    ];
    return {
      ...state,
      activeEvents: activeEvent
    };
  
  case actionTypes.FAILED_GET_PAST_EVENTS:
    return {
      ...state,
      pastEventsError: action.pastEventsError,
      isLoading: false
    };

  case actionTypes.GET_PAST_EVENTS:
    let pastEvent = [];
    pastEvent = action.PageNumber === 1 ? [...action.pastEvents] : [
      ...state.pastEvents,
      ...action.pastEvents
    ];
    return {
      ...state,
      pastEvents: pastEvent,
      isLoading: false
    };
  
  case actionTypes.GET_EVENT_DETAILS:
    return {
      ...state,
      eventDetail: action.eventDetail,
      isLoading: false
    };
  case actionTypes.GET_SEARCHED_ATTENDEES_LIST:
    return {
      ...state,
      searchedAttendees: action.searchedAttendees
    };
  case actionTypes.FAILED_GET_EVENT_DETAILS:
    return {
      ...state,
      eventDetailError: action.eventDetailError,
      isLoading: false
    };

  case actionTypes.GET_COMMENTS_ON_EVENT:
    return {
      ...state,
      comments: descendingOrderSort(action.comments),
      isLoading: false
    };

  case actionTypes.FAILED_GET_COMMENTS_ON_EVENT:
    return {
      ...state,
      commentError: action.commentError,
      isLoading: false
    };

  case actionTypes.ADD_COMMENT_ON_EVENT:
    return {
      ...state,
      commentSuccess: action.commentSuccess
    };

  case actionTypes.FAILED_ADD_COMMENT_ON_EVENT:
    return {
      ...state,
      postCommentError: action.postCommentError
    };

  case actionTypes.ADD_COMMENT_REPLY_ON_EVENT:
    return {
      ...state,
      replySuccess: action.replySuccess
    };

  case actionTypes.FAILED_ADD_COMMENT_REPLY_ON_EVENT:
    return {
      ...state,
      replyError: action.replyError
    };

  case actionTypes.ATTEND_EVENT:
    return {
      ...state,
      attendSuccess: action.attendSuccess
    };

  case actionTypes.FAILED_ATTEND_EVENT:
    return {
      ...state,
      attendError: action.attendError
    };

  case actionTypes.UPDATE_EVENT:
    let updatedData = {...state.eventDetail};
    if(updatedData.id === action.id) {
      updatedData.is_attending = action.isAttending === 1 ? 0 : 1;
    }
    if(action.updationList === 'events') {
      const newFeaturedEvents = state.featureEvents && updateList(state.featureEvents, action.id, action.isAttending);
      const newEvents = state.events && updateList(state.events, action.id, action.isAttending);
      return {
        ...state,
        featureEvents: newFeaturedEvents,
        events: newEvents,
        eventDetail: updatedData
      }
    } else if(action.updationList === 'eventDetail') {
      const newFeaturedEvents = state.featureEvents && updateList(state.featureEvents, action.id, (action.isAttending === 1 ? 0 : 1));
      const newEvents = state.events && updateList(state.events, action.id, (action.isAttending === 1 ? 0 : 1));

      return {
        ...state,
        events: newEvents,
        featureEvents: newFeaturedEvents
      }
    } else {
      const newFeaturedEvents = state.featureEvents && updateList(state.featureEvents, action.id, action.isAttending);
      const newEvents = state.events && updateList(state.events, action.id, action.isAttending);
      return {
        ...state,
        featureEvents: newFeaturedEvents,
        events: newEvents,
        eventDetail: updatedData
      }
    }

  case actionTypes.GET_EVENT_ATTENDEES:
    return {
      ...state,
      attendees: action.attendees
    };

  case actionTypes.FAILED_GET_EVENT_ATTENDEES:
    return {
      ...state,
      attendeesError: action.attendeesError
    };

  case actionTypes.SET_ATTENDING_STATUS:
    return {
      ...state,
      attending: action.isAttending
    };

  case actionTypes.GET_ATTENDING_STATUS:
    return {
      ...state,
      attending: state.attending
    };

  case actionTypes.FAILED_ONDEMAND_EVENT_DETAILS:
    return {
      ...state,
      ondemandEventDetailErr: action.ondemandEventDetailErr,
      isLoading: false
    };
  case actionTypes.ONDEMAND_EVENT_DETAILS:
    return {
      ...state,
      ondemandEventDetail: action.ondemandEventDetail,
      isLoading: false
    };
  case actionTypes.FAILED_ONDEMAND_CATEGORY:
    return {
      ...state,
      ondemandCategoryErr: action.ondemandCategoryErr,
      isLoading: false
    };
  case actionTypes.ONDEMAND_CATEGORY:
    return {
      ...state,
      ondemandCategory: action.ondemandCategory,
    };
  case actionTypes.ONDEMAND_CATEGORY_BY_ID:
    return {
      ...state,
      ondemandByIdData: action.ondemandByIdData,
    };
  case actionTypes.DELETE_ONDEMAND:
    return {
      ...state,
      deleteOndemand: action.deleteOndemand,
    };
  case actionTypes.DELETE_EVENT:
    return {
      ...state,
      deleteEvent: action.deleteEvent,
    };
  case actionTypes.GET_COMPANY_EVENTS:
    return {
      ...state,
      companyEvents: action.companyEvents,
    };
  case actionTypes.GET_WOLIBA_EVENTS:
    return {
      ...state,
      wolibaEvents: action.wolibaEvents,
    };
  case actionTypes.GET_USER_EVENT_INVITATION:
    return {
      ...state,
      inviteEventUsers: action.inviteEventUsers
    };
  case actionTypes.GET_USER_LIST_EVENTS:
    return {
      ...state,
      eventUserList: action.eventUserList
    };    
  default:
    break;
  }
  return state;
};

export default reducer;
